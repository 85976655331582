import { InteractionConfig } from './types';
import { AnimatePresence, motion } from 'framer-motion';

export default function Interaction(props: {
	shouldPlay: boolean;
	config: InteractionConfig;
	onLoaded: () => void;
	onDone: () => void;
	onError: (e: Error) => void;
}) {
	// call after preloading is done F1E9DA
	props.onLoaded();
	// call onDone when your interaction is done or after some timeout
	setTimeout(() => {
		props.onDone();
	}, props.config.timeout);

	return (
		<AnimatePresence>
			<div className="relative w-full h-full">
				{/* <audio src="/clippysound.mp3" autoPlay={true} /> */}
				<motion.div

					initial={{ opacity: 0, y: 50 }}
					exit={{ opacity: 0, y: 50 }}
					animate={{ opacity: 1, y: 0 }}
					transition={{ duration: 0.2, delay: 0 }}
				>
					<div className="relative flex flex-col items-center border-none">
						<div className="relative flex items-center space-x-4 bg-[#F1E9DA] rounded-xl p-4 max-w-full break-words border-none">
							<div className="min-w-[400px] max-w-4xl pr-10 border-none">
								<p className="text-center text-2xl font-a text-black overflow-hidden border-none">
									{props.config.msgFrom}: {props.config.msg}
								</p>
							</div>
							<div className="absolute -bottom-9 left-20 w-0 h-0 border-t-[#F1E9DA] border-t-[40px] border-x-transparent border-y-transparent border-x-[25px]"></div>
							
						</div>
						{/* <img alt="jesus" src="./clippy.gif" className="mt-16 h-80 w-90" /> */}
						<video autoPlay={true} className="h-100 w-100" src='giffyvid.webm'/>
					</div>
				</motion.div>
			</div>
		</AnimatePresence>
	);
}

